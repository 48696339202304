// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-classroom-js": () => import("./../../../src/pages/classroom.js" /* webpackChunkName: "component---src-pages-classroom-js" */),
  "component---src-pages-faq-site-js": () => import("./../../../src/pages/FAQ-Site.js" /* webpackChunkName: "component---src-pages-faq-site-js" */),
  "component---src-pages-holiday-card-js": () => import("./../../../src/pages/holiday-card.js" /* webpackChunkName: "component---src-pages-holiday-card-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marketing-js": () => import("./../../../src/pages/marketing.js" /* webpackChunkName: "component---src-pages-marketing-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-page-3-js": () => import("./../../../src/pages/page-3.js" /* webpackChunkName: "component---src-pages-page-3-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

